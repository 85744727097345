<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Residencias',

    metaInfo: { title: 'Residencias' },
    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
       // 'ConvocatoriaCertificacion',
        //'ConvocatoriaRecertificacion',
        //'CitatorioAsamblea',
        'residencias',
        'info-alt',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'pricing-plans',
      },
    },
  }
</script>
